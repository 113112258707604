<template>
  <div class="hello">
    <img src="@/assets/cloud.png" class="cover" />
    
    <b-row>
        <b-col cols="3" class="img-container" >
            <img src="../assets/yas.png" class="me" />
        </b-col>
        
        <b-col col sm="12" md="9" >
            <p style="margin-top: 30px;" > I am a Brazilian researcher/developer that likes a lot learning new technologies as well as accepting and accomplishing challenges. </p>
            <p style="margin-top: 30px;" > I also have some hobbies like reading, learning new languages in Duolingo (French, German, Italian and Russian), swimming, drawing animals in a paper and buildings in AutoCad. </p>
            <p style="margin-top: 30px;" > Topics I have been working with: <b-badge pill variant="primary"> bioinformatics</b-badge> , <b-badge pill variant="primary">semantic web</b-badge>, <b-badge pill variant="primary">machine learning</b-badge>, <b-badge pill variant="primary">data analysis</b-badge>, <b-badge pill variant="primary">natural language processing</b-badge>, <b-badge pill variant="primary">back end</b-badge>, <b-badge pill variant="primary">front end</b-badge>. </p>
        </b-col>
    </b-row>
    
    
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

