<template>
  <footer>
    <b-row>
        <b-col cols="6" style="text-align: left;" >
            <p style="font-weight: bold">
                Contact: <br />
                Yasmmin Côrtes Martins <br /> 
                E-mail: <a href="mailto:yasmmin.c.martins@gmail.com" target="_blank" >yasmmin.c.martins@gmail.com</a> <br />
                Github: <a href="https://github.com/YasCoMa" target="_blank" >My Repositories</a> <br />
                Linkedin: <a href="https://www.linkedin.com/in/yasmmin-martins-34506a44" target="_blank" >Go to Profile</a> <br />
                Google Scholar: <a href="https://scholar.google.com.br/citations?user=tSwLakcAAAAJ&hl=pt" target="_blank" >Go to Profile</a>
            </p>
        </b-col>
    </b-row>
  
    <p>Yasmmin Côrtes Martins &copy; {{ year }} </p>
  </footer>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'AppFooter',
  setup () {
      const year = computed(() => new Date().getFullYear())
      return { year }
    }
}
</script>

