<template>
  <div >
  <b-row>
    <b-col cols="12">
        
    <h2>Academic Background</h2>
        <b-row class="info">
        
            <b-col col sm="12" md="6" class="academic" >
                <p > <span class="number-progress" > 1</span> <b>Graduated</b> in System's analysis and development at Fluminense Federal Institute, where I developed a web system to apply cognitive tests of memory and attention generating reports in the administration page. All the data about this kind of tests and the terminology is recorded following the linked data principles in order to encourage other systems to adopt the same terminology and facilitate the analysis and querying using the same file format. </p>
            </b-col>
            
            <b-col col sm="12" md="6" class="academic">
                <p  > <span class="number-progress" > 2</span> <b>Master's degree</b> in Systems and Information at Military Institute of Engineering. I developed a dissertation about data interlinking on the web of data in the semantic web context, having as target since the ranking of the best datasets publicly available to link to some source without external links, till the data items mapping between the selected datasets and the source one, human validation using an online platform by crowdsourcing to validate the items mapping, and at the end making the data fusion between source dataset and the validated items. </p>
           </b-col>
            
      </b-row>      
      
      <b-row class="info">
        
            <b-col col sm="12" md="6" class="academic" >
                <p  > <span class="number-progress" > 3</span> <b>Doctor's degree</b> in Computational modelling at the National Laboratory of Scientific Computing where I developed a thesis about protein interaction prediction, using multiple evidence of biological information which gives different shadows about physical and functional associations between proteins and massive use of machine learning to combine detection methods and posterior classification. </p>
            </b-col>
            
            <b-col col sm="12" md="6" class="academic" >
                <p  > <span class="number-progress" > 4</span> I worked as a <b>postdoctoral researcher</b> at the National Laboratory of Scientific Computing, specifically, in the bioinformatics laboratory, from 12/2020 to 01/2022 analyzing huge amount of genomic data for the SARS-CoV-2 projects, using bioinformatic tools to map, align and annotate proteins. I also had the opportunity to learn and execute structural analysis on proteins and perform docking essays. Most of the analysis relied on data exploration, visualization, prediction and forecasting. I and the bioinformatics lab team produced research articles in order to share the findings. I also have experience of presenting short-term courses teaching Python language with biological study cases. In 2016 and 2019, I could participate in two international conferences to present two of my research articles.</p>
            </b-col>
        
        </b-row>
    
    <h2>Web Development Experience</h2>
        <b-col cols="12">
            I worked full time as a full stack developer, for the MTW company from 05/2013 to 02/2014, but I ocasionally colaborate and act as consultant. We developed a mangement system named PortalGov to organize the administrative data of city halls and municipal chambers such as news, tourism facilities, legislation, bidding, organizational structure, etc. Portalgov is composed of a laravel API and a Vue js front end, storing data in a relational database. Other minor projects in this same company involved the use of Code igniter framework, Ruby on rails, Flutter and Angular. In this company, I also have opportunity to talk with the clients in requirement acquisition stage, functional validation and training users to use the systems.
        </b-col>
        
    </b-col>
  </b-row>
   
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
