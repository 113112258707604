<template>
  <div class="portfolio">
    <b-row>
        <b-col cols="12">
        
            <!-- Project 1 - dscrank -->
            <a href="https://github.com/YasCoMa/dscrank" target="_blank" >
                <b-row style="margin-bottom: 15px;" >
                    <b-col col md="3" class="img-container" >
                        <img src="../assets/img-projects/proj1-dscrank.png" class="proj-figure" />
                    </b-col>
                    
                    <b-col col sm="12" md="9"  style="text-align: center;"  >
                        <div class="proj-info">
                            <h4> DSCrank </h4>
                            <p > This project aims at selecting the best datasets, align dataset terms and executing datafusion on semantic web datasets in order to be in accord with the Linked Data Principles. It is composed of four main modules: </p>
                            <ul>
                                <li>First - Given a local dataset that the user wants to share and connect its concepts with other semantic datasets, the first module executes an exhaustive search on datasets repositories such as data hub. Than, after web crawling, it extracts the concepts and triples, using natural laguage processing techniques. Then, it returns a ranked list of datasets that are most closer to the subject of the input dataset by using a personalized scoring function.</li>
                                <li>Second - Using six string similarity metrics, the second module analyzes the similarity between two terms (local dataset x another dataset), saving the best matches using majority vote.</li>
                                <li>Third -  The user has an option to send for human validation to be sure that the matched pairs are correct. So, this modules sends the pairs to be validated in the <a style="display: contents;" href="https://ypublish.info/crowdsourcing-validation" target="_blank" >web application</a> of crowdsourcing </li>
                                <li>Fourth - The last module retrieves the results from the web application and executes the data fusion, interlinking correctly and modifying the ontology rules to merge the concepts/class items from local and external datasets.</li>
                            </ul>
                            <p > Tags: <b-badge pill variant="primary"> Java</b-badge> , <b-badge pill variant="primary">semantic web</b-badge>,  <b-badge pill variant="primary">scoring function</b-badge>, <b-badge pill variant="primary">Desktop application</b-badge>, <b-badge pill variant="primary">natural language processing</b-badge>, <b-badge pill variant="primary">HTML/CSS/Bootstrap</b-badge>, <b-badge pill variant="primary">Javascript</b-badge>. </p>
                         </div>
                    </b-col>
                </b-row>
            </a>
            
            <!-- Project 2 - predprin -->
            <a href="https://github.com/YasCoMa/predprin" target="_blank" >
                <b-row style="margin-bottom: 15px;" >
                    <b-col col md="3" class="img-container">
                        <img src="../assets/img-projects/proj2-predprin.png" class="proj-figure" />
                    </b-col>
                    
                    <b-col col sm="12" md="9"  style="text-align: center;"  >
                        <div class="proj-info">
                            <h4> PredPrIn </h4>
                            <p > PredPrIn is a scientific workflow to predict Protein-Protein Interactions (PPIs) using machine learning to combine multiple PPI detection methods of proteins according to three categories: structural, based on primary aminoacid sequence and functional annotations. It is composed of three main modules: </p>
                            <ul>
                                <li>First - Construction of a knowledge base with the available annotations of proteins and reuses this base for other prediction experiments, saving time and becoming more efficient.</li>
                                <li>Second - Numerical feature generation from several evidence from different classes, such as: Gene Ontology (GO) information, domain interaction, metabolic pathway participation and sequence-based interaction. For the GO branches, we made a study to evaluate the best method to calculate semantic similarity to enhance the workflow performance. In this module, I implemented a modified version of the original stacking ensemble technique adapted to the biological domain of interest.</li>
                                <li>Third -  Classification of the samples with the Adaboost classifier, performance evaluation metrics and model. </li>
                            </ul>
                            <p > Tags: <b-badge pill variant="primary"> Python </b-badge> , <b-badge pill variant="primary">Gene Ontology</b-badge>,  <b-badge pill variant="primary">semantic similarity</b-badge>, <b-badge pill variant="primary">scientific workflow</b-badge>, <b-badge pill variant="primary">parallelization</b-badge>, <b-badge pill variant="primary">machine learning</b-badge>. </p>
                         </div>
                    </b-col>
                </b-row>
            </a>
            
            <!-- Project 3 - ppivpro -->
            <a href="https://github.com/YasCoMa/ppi_validation_process" target="_blank" >
                <b-row style="margin-bottom: 15px;" >
                    <b-col col md="3" class="img-container">
                        <img src="../assets/img-projects/proj3-ppivpro.png" class="proj-figure" />
                    </b-col>
                    
                    <b-col col sm="12" md="9"  style="text-align: center;"  >
                        <div class="proj-info">
                            <h4> PPIVPro </h4>
                            <p > Python pipelines to filter positive predicted protein interactions according to two criteria: (i) association rules of cellular components according to gold standard Protein Protein Interaction data from <a href="http://hint.yulab.org/" target="_blank" >HINT</a> and (ii) text mining on scientific papers published on Pubmed extracting sentences where the proteins in the PPIs appeared in an interaction context </p>
                            <p > Tags: <b-badge pill variant="primary"> Python </b-badge> , <b-badge pill variant="primary">rules association</b-badge>,  <b-badge pill variant="primary">natural language processing</b-badge>, <b-badge pill variant="primary">machine learning</b-badge>. </p>
                         </div>
                    </b-col>
                </b-row>
            </a>
            
            <!-- Project 4 - epicurator -->
            <a href="https://github.com/YasCoMa/EpiCurator" target="_blank" >
                <b-row style="margin-bottom: 15px;" >
                    <b-col col md="3" class="img-container">
                        <img src="../assets/img-projects/proj4-epicurator.png" class="proj-figure" />
                    </b-col>
                    
                    <b-col col sm="12" md="9"  style="text-align: center;"  >
                        <div class="proj-info">
                            <h4> EpiCurator </h4>
                            <p > This pipeline contains a series of functions to filter small amino acid sequences (peptides) predicted by epitopes discovery tools. It parses files from BepiPred and Discotope tools and executes filtering and descriptive steps to refine the final list of epitopes. One of the modules (Epiminer) executes text mining on scientific papers directed to the search of these peptides in a context of epitopes prediction and immunology in order to check the originality of the user's epitopes. </p>
                            <p > Tags: <b-badge pill variant="primary"> Python </b-badge> , <b-badge pill variant="primary">data analysis</b-badge>,  <b-badge pill variant="primary">results exportation</b-badge> and  <b-badge pill variant="primary">natural language processing</b-badge>. </p>
                         </div>
                    </b-col>
                </b-row>
            </a>
            
            <b-row >
                <!-- Project 5 - sco -->
                <b-col col sm="12" md="6" >
                    <a href="https://github.com/YasCoMa/SCO" target="_blank" >
                        <b-card no-body class="overflow-hidden">
                            <b-row no-gutters>
                              <b-col col md="6" class="img-container">
                                <b-card-img :src="require('../assets/img-projects/proj5-sco.png')" alt="Image" class="rounded-0"></b-card-img>
                              </b-col>
                              
                              <b-col col sm="12" md="12" lg="6" xl="6" >
                                <b-card-body title="Online House System">
                                  <b-card-text>
                                    SCO (Online House System) project is a mobile app for android in Java that send commands to an Internet module of arduino and then executes a simple command sending electronic pulses. It was built as a project of house automation.
                                    <p > Tags: <b-badge pill variant="primary"> Java</b-badge> , <b-badge pill variant="primary">Android</b-badge>,  <b-badge pill variant="primary">PHP API</b-badge>. </p>
                                  </b-card-text>
                                </b-card-body>
                              </b-col>
                              
                            </b-row>
                          </b-card>
                      </a>
                </b-col>
                
                
                <!-- Project 6 - sco -->
                <b-col col sm="12" md="6" >
                    <a href="https://github.com/YasCoMa/site_comercial_ruby_agro" target="_blank" >
                        <b-card no-body class="overflow-hidden">
                            <b-row no-gutters>
                              <b-col  col md="6" class="img-container" >
                                <b-card-img :src="require('../assets/img-projects/proj6-rubyagro.png')" alt="Image" class="rounded-0"></b-card-img>
                              </b-col>
                              
                              <b-col col sm="12" md="12" lg="6" xl="6" >
                                <b-card-body title="Online Store for Agricultural Items">
                                  <b-card-text>
                                    This is a project with back and front end mixed as an online store for Agricultural items, implementing user access restriction, shopping basket, order stages, etc.
                                    <p > Tags: <b-badge pill variant="primary"> Ruby on Rails</b-badge> , <b-badge pill variant="primary">HTML/CSS/Javascript</b-badge>. </p>
                                  </b-card-text>
                                </b-card-body>
                              </b-col>
                              
                            </b-row>
                         </b-card>
                     </a>
                </b-col>
                
            </b-row>
            
        </b-col>
    </b-row>
    
  </div>
</template>

<script>
export default {
  name: 'Portfolio'
}
</script>

