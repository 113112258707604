<template>
  <div>
  
    <teleport to="head">
        <meta name="description" content="I am a researcher on the bioinformatics field that does data science and data analysis. Meanwhile I also develop web applications." >
        <meta name="robots" content="index,follow" >
        <meta name="og:title" content="Data Analyst, data scientist and Web Developer - Yasmmin Martins" >
        <meta name="og:type" content="website" >
        <meta name="og:url" content="https://www.ypublish.info" >
        <meta name="og:site_name" content="Yasmmin Martins" >
        <meta name="og:description" content="I am a researcher on the bioinformatics field that does data science and data analysis. Meanwhile I also develop web applications." >
        <meta name="twitter:card" content="summary" >
        <meta name="twitter:title" content="Data Analyst, data scientist and Web Developer - Yasmmin Martins" >
        <meta name="twitter:description" content="I am a researcher on the bioinformatics field that does data science and data analysis. Meanwhile I also develop web applications." >
    </teleport>
  
    <app-header />
    
    <div class="container main">
        <div class="container-fluid">
          
          <home />
          
          <section id="portfolio">
              <div class="separator"> Portfolio </div>
              <portfolio />
          </section>
          
          <section id="about-me">
              <div class="separator"> About Me </div>
              <about />
          </section>
          
          <!--<router-view/>-->
          
        </div>
    </div>
    
    <app-footer />
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'

import Home from '@/components/Home.vue'
import About from '@/components/About.vue'
import Portfolio from '@/components/Portfolio.vue'

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    Home,
    About,
    Portfolio   
  },
  mounted(){
    document.title="Data Analyst, data scientist and Web Developer - Yasmmin Martins";
  }
}
</script>

