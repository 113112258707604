import {createRouter, createWebHistory} from "vue-router";

import Home from '@/components/Home.vue'
import About from '@/components/About.vue'

let checkAuth = (to, from, next) => {
  document.title = to.meta.title
  next()
}

const routes= [
        {
            path: '/',
            name: 'home',
            component: Home,
            children: [
              {
                name: '',
                path: 'home',
                component: Home,
                beforeEnter: checkAuth,
                meta: {
                    title: 'Homepage'
                }
              }
            ]
      },
      {
          path: '/about',
          name: 'about',
          component: About,
            meta: {
                title: 'About Me'
            }
      }
    ]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router;

