<template>
  <header>
    <img alt="Yas logo" src="../assets/new_logo.png" class="logo">
    <!--<p>Portfolio</p>-->
    <nav>
      <ul>
        <!--<li><router-link :to="{ name: 'home' }">Home</router-link></li>
        <li><router-link :to="{ name: 'about' }">About Me</router-link></li>-->
        
        <li><a href="/">Home</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#about-me">About Me</a></li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'AppHeader'
}
</script>

